import {flare} from "@flareapp/js";

flare.beforeSubmit = (report) => {

    flare.glow('report', 'info', [
        JSON.stringify(report)
    ]);

    // Filter out errors that are not useful
    if ([
        'Request failed with status code 401',
        'Network Error',
        'Failed to fetch dynamically imported module',
        'is not a valid JavaScript MIME type',
        'Unable to preload CSS',
        'Importing a module script failed.',
        'Cannot read properties of null (reading \'setupContext\')'
    ].some(v => report.message.includes(v))) {
        return false;
    }

    // Filter silly bots
    if ([
        'adsbot',
        'googlebot'
    ].some(v => report.context.request.useragent.includes(v))) {
        return false;
    }

    return report;
};

export default flare;
